<template>
  <div class="basic-data-add">
    <el-card class="add-card">
      <div class="form_title b mb30 mt15">
        {{ title }}{{ $t("lang.gles.strategy.robotGoodsPositionStrategy") }}
      </div>
      <div class="basicInfo">
        <el-divider content-position="left" class="mb30">
          {{ $t("lang.gles.common.basicInfo") }}
        </el-divider>
        <m-form
          ref="myForm"
          :form-data="basicFormData"
          label-position="right"
          :label-width="120"
          :extend-config="extendFormConfig"
        >
          <template #inputCodeTipIcon>
            <el-tooltip
              popper-class="workshop_code_tip-icon"
              effect="dark"
              :content="$t(autoGeneCodeTip)"
              placement="right"
            >
              <i class="el-icon-tip el-icon-question tip" />
            </el-tooltip>
          </template>
        </m-form>
      </div>
      <!-- 匹配条件 -->
      <div class="targetRange">
        <el-divider content-position="left" class="mb30">
          {{ $t("lang.gles.strategy.matchCondition") }}
        </el-divider>
        <m-form
          ref="myMatchForm"
          :form-data="matchConditionFormData"
          label-position="right"
          :label-width="120"
          :extend-config="extendFormConfig"
        />
      </div>
      <!-- 货位配置 -->
      <div class="appStrategy">
        <el-divider content-position="left" class="mb30">
          {{ $t("lang.gles.strategy.goodsPositionConfig") }}
        </el-divider>
        <m-form
          ref="myGoodsPositionConfigForm"
          :form-data="goodsPositionConfigFormData"
          label-position="right"
          :label-width="120"
          :extend-config="extendFormConfig"
        />
      </div>
      <div class="footer-btns">
        <el-button @click="onCancel">
          {{ $t("lang.gles.common.cancel") }}
        </el-button>
        <el-button v-debounce="onSubmit" type="primary">
          {{ $t("lang.gles.common.confirm") }}
        </el-button>
      </div>
    </el-card>
  </div>
</template>
<script>
import MForm from '@/libs_sz/components/MForm/MForm'
import addMixins from '@/mixins/addMixins'
import { getEditBaseFormData, getMatchConditionFormData, getGoodsPositionConfigFormData } from '../data'
import myTransform from '@/libs_sz/utils/transform'
export default {
  name: 'AddGoodsPositionStrategy',
  components: {
    MForm
  },
  mixins: [addMixins],
  props: {
    mode: {
      type: String,
      default: 'add'
    },
    rowDetail: {
      type: Object,
      default: () => { }
    },
    options: {
      type: Object,
      default: () => { }
    }
  },
  data() {
    return {
      title: this.mode === 'add' ? this.$t('lang.gles.common.addNew') : this.mode === 'edit' ? this.$t('lang.gles.common.edit') : this.$t('lang.gles.common.copy'),
      strategyTableData: [],
      // form表单相关的
      extendFormConfig: {
        isNeedBtn: false
      },
      classifySecondList: [],
      materialCodeList: [],
      turnoverContainerCodeList: []
    }
  },
  computed: {
    basicFormData() {
      const row = this.rowDetail || {}
      return getEditBaseFormData(this, { ...row })
    },
    matchConditionFormData() {
      const row = this.rowDetail || {}
      return getMatchConditionFormData(this, this.options, { ...row })
    },
    goodsPositionConfigFormData() {
      const row = this.rowDetail || {}
      if (this.mode === 'edit' || this.mode === 'copy') {
        row.containerDirection = row.containerDirection ? row.containerDirection.split(',') : ''
        row.robotModel = row.robotModel ? row.robotModel.split(',') : ''
      }
      return getGoodsPositionConfigFormData(this, this.options, { ...row })
    }
  },
  created() {
    if (this.mode === 'edit' || this.mode === 'copy') {
      const rowDetail = this.rowDetail || {}
      const classifyParams = rowDetail.materialFirstClassify ? { parentId: rowDetail.materialFirstClassify } : {}
      this.getClassifyByFirstClassify(classifyParams)
      const materialParams = {}
      rowDetail.materialFirstClassify ? materialParams.classifyFirst = rowDetail.materialFirstClassify : ''
      rowDetail.materialSecondClassify ? materialParams.classifySecond = rowDetail.materialSecondClassify : ''
      this.getMaterialCodeList(materialParams)
      this.getTurnoverContainerCodeList(rowDetail.turnoverContainerType)
      return
    }
    this.getClassifyByFirstClassify()
    this.getMaterialCodeList()
    this.getTurnoverContainerCodeList()
  },
  methods: {
    // 根据一级分类级联二级分类
    async getClassifyByFirstClassify(params) {
      const { code, data } = await this.$httpService(this.$apiStore.materialManage('queryClasifyByCodeOrName'), { ...params, level: 2 })
      if (code) return
      this.classifySecondList = myTransform.arrToOptions(data, 'classifyName', 'id')
    },
    // 获取物料编码的下拉数据
    async getMaterialCodeList(params) {
      const { code, data } = await this.$httpService(this.$apiStore.materialManage('queryMaterialOption'), params)
      if (code) return
      this.materialCodeList = data.map(item => { return { label: item.materialCode, value: item.id } })
    },
    /**
     * 一级级联二级和物料编码
     */
    onClassifyFirstChange(val, formItem, changeFormValue, formModel) {
      const formData = this.$refs.myMatchForm.formModel
      this.$set(formData, formItem.name, val)
      formData.materialSecondClassify = 0
      formData.materialCode = 0
      const params = formData.materialFirstClassify ? { parentId: formData.materialFirstClassify } : {}
      const materialParams = formData.materialFirstClassify ? { classifyFirst: formData.materialFirstClassify } : {}
      this.getClassifyByFirstClassify(params)
      this.getMaterialCodeList(materialParams)
    },
    /**
     *二级级联--物料编码
     */
    onClassifySecondChange(val, formItem, changeFormValue, formModel) {
      const formData = this.$refs.myMatchForm.formModel
      this.$set(formData, formItem.name, val)
      formData.materialCode = 0
      const materialParams = {}
      formData.materialFirstClassify ? materialParams.classifyFirst = formData.materialFirstClassify : ''
      formData.materialSecondClassify ? materialParams.classifySecond = formData.materialSecondClassify : ''
      this.getMaterialCodeList(materialParams)
    },
    // 根据容器类查询容器编码
    async getTurnoverContainerCodeList(val) {
      const params = {}
      val ? params.containerTypeIds = [val] : params.containerTypePatterns = ['containerPatternBarrel', 'containerPatternCrate']
      const { code, data } = await this.$httpService(this.$apiStore.materialManage('getContainerCode'), params)
      if (code) return
      this.turnoverContainerCodeList = myTransform.arrToOptions(data, 'containerArchivesCode', 'id')
    },
    // edit或copy 下获取详情
    async queryGoodsPositionStrategyDetail() {
      const { code, data } = await this.$httpService(this.$apiStore.strategyManage('queryGoodsPositionStrategyDetail'), { id: this.rowDetail.id })
      if (code) return
      // 回显
      console.log(data, 'data09000000')
    },
    onSubmit() {
      this.$refs.myForm.getValidateFormModel().then(async(model) => {
        this.$refs.myMatchForm.getValidateFormModel().then(async(matchFormModel) => {
          this.$refs.myGoodsPositionConfigForm.getValidateFormModel().then(async(goodsPositionFormModel) => {
            const params = { ...this.rowDetail, ...model, ...matchFormModel, ...goodsPositionFormModel }
            params.containerDirection = Array.isArray(params.containerDirection) ? params.containerDirection.join(',') : params.containerDirection
            params.robotModel = Array.isArray(params.robotModel) ? params.robotModel.join(',') : params.robotModel
            // console.log(params, 'params')
            if (this.mode === 'copy') {
              delete params.id
            }
            this.mode === 'edit' ? await this.update(params) : await this.insert(params)
          })
        })
      })
    },
    async insert(params) {
      const { code } = await this.$httpService(this.$apiStore.strategyManage('addGoodsPositionStrategy'), params)
      if (code) return
      this.mode === 'add' ? this.$message.success(this.$t('lang.gles.common.addSuccessfully')) : this.$message.success(this.$t('lang.gles.strategy.copySuccessfully'))
      this.onCancel()
    },
    async update(params) {
      const { code } = await this.$httpService(this.$apiStore.strategyManage('updateGoodsPositionStrategy'), params)
      if (code) return
      this.$message.success(this.$t('lang.gles.common.updateSuccessfully'))
      this.onCancel()
    },
    // 取消
    onCancel() {
      this.$emit('updateCom', {
        currentCom: 'robotStorageStrategyList',
        mode: ''
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.form_title {
  border-left: 4px solid #409eff;
  height: 18px;
  line-height: 20px;
  padding-left: 8px;
  font-size: 16px;
}
.tip {
  display: inline-block;
  position: absolute;
  top: 6px;
  right: -24px;
}
.el-card__body {
  display: flex;
  flex-direction: column;
  .footer-btns {
    flex: 0 0 60px;
    left: 201px;
    bottom: 20px;
    z-index: 10;
    width: calc(100vw - 212px);
    height: 60px;
    line-height: 60px;
    margin-top: 10px;
    text-align: center;
  }
}
</style>
