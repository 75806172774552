import mapVerify from '@/utils/formValidate'
import { t } from '@/libs_sz/locale'
import myTransform from '@/libs_sz/utils/transform'
import { goodsPositionNum } from '@/constant/constant.js'
const getFormatter = (data, value) => {
  const datas = [{ label: t('lang.gles.common.all'), value: 0 }].concat(data)
  const getValue = myTransform.arrToObject(datas, 'value', 'label')[value] || value
  return getValue === 'null' || getValue === 0 ? '' : getValue
}
const formatOptions = data => { return [{ label: t('lang.gles.common.all'), value: 0 }].concat(data) }
export const getMoreQueryFormData = (that, row) => {
  const moreQueryData = [
    // 策略编码
    {
      name: 'strategyCode',
      span: 12,
      component: 'elInput',
      label: that.$t('lang.gles.strategy.strategyCode'),
      clearable: true
    },
    // 策略名称
    {
      name: 'strategyName',
      span: 12,
      component: 'elInput',
      label: that.$t('lang.gles.strategy.strategyName'),
      clearable: true
    },
    // 周转容器类型
    {
      name: 'turnoverContainerType',
      span: 12,
      component: 'elSelect',
      label: that.$t('lang.gles.material.turnoverContainerType'),
      clearable: true,
      data: formatOptions(that.turnoverContainerTypeList || []),
      mchange(val, item, cb) {
        that.getTurnoverContainerCodeList(val)
        cb({ turnoverContainerCode: '' })
      }
    },
    // 周转容器编码
    {
      name: 'turnoverContainerCode',
      span: 12,
      component: 'elSelect',
      label: that.$t('lang.gles.orderManage.turnoverContainerId'),
      clearable: true,
      data: formatOptions(that.turnoverContainerCodeList || [])
    },
    // 物料一级分类
    {
      name: 'materialFirstClassify',
      span: 12,
      component: 'elSelect',
      label: that.$t('lang.gles.common.materialFirstClassify'),
      clearable: true,
      data: formatOptions(that.materialClassifyFirstList || []),
      mchange: that.onClassifyFirstChange
    },
    // 物料二级分类
    {
      name: 'materialSecondClassify',
      span: 12,
      component: 'elSelect',
      label: that.$t('lang.gles.common.materialSecondClassify'),
      clearable: true,
      data: formatOptions(that.classifySecondList || []),
      mchange: that.onClassifySecondChange
    },
    // 物料编码
    {
      name: 'materialCode',
      span: 12,
      component: 'elSelect',
      label: that.$t('lang.gles.material.materialCode'),
      data: formatOptions(that.materialCodeList || [])
    },
    // 任务类型
    {
      name: 'taskType',
      span: 12,
      component: 'elSelect',
      label: that.$t('lang.gles.strategy.taskType'),
      clearable: true,
      data: formatOptions(that.taskTypeList.filter(item => Number(item.value) !== 50) || [])
    },
    // 机器人系列
    {
      name: 'robotType',
      span: 12,
      component: 'elSelect',
      label: that.$t('lang.gles.strategy.robotSeries'),
      clearable: true,
      data: that.robotTypeList.filter(item => Number(item.value) !== 3) || []
    },
    // 机器人型号
    {
      name: 'robotModel',
      span: 12,
      component: 'elSelect',
      label: that.$t('lang.gles.strategy.robotModel'),
      clearable: true,
      data: that.robotModelList || []
    },
    // 容器面
    {
      name: 'containerDirection',
      span: 12,
      component: 'elSelect',
      label: that.$t('lang.gles.base.containerDirection'),
      clearable: true,
      data: that.containerDirectionList || []
    },
    // 货位序号
    {
      name: 'goodsPositionSerialNumber',
      span: 12,
      component: 'elSelect',
      label: that.$t('lang.gles.base.goodsPositionNum'),
      clearable: true,
      data: goodsPositionNum || []
    },
    // status
    {
      name: 'status',
      span: 12,
      component: 'elSelect',
      label: that.$t('lang.gles.base.status'),
      clearable: true,
      data: that.statusFlagList || []
    }
  ]
  return moreQueryData
}
// 查询列表列表
export const getSearchTableItem = (that, options) => {
  const searchTableItem = [
    // 策略编码
    {
      prop: 'strategyCode',
      sortable: true,
      label: that.$t('lang.gles.strategy.strategyCode'),
      minWidth: 120,
      isShow: true
    },
    // 策略名称
    {
      prop: 'strategyName',
      label: that.$t('lang.gles.strategy.strategyName'),
      isShow: true
    },
    // 周转容器类型
    {
      prop: 'turnoverContainerType',
      label: that.$t('lang.gles.material.turnoverContainerType'),
      formatter(row, column) {
        return getFormatter(that.turnoverContainerTypeList, row[column])
      },
      isShow: true
    },
    // 周转容器编码
    {
      prop: 'turnoverContainerCode',
      label: that.$t('lang.gles.orderManage.turnoverContainerId'),
      formatter(row, column) {
        return getFormatter(that.turnoverContainerCodelist, row[column])
      },
      isShow: true
    },
    // 物料一级分类
    {
      prop: 'materialFirstClassify',
      label: that.$t('lang.gles.common.materialFirstClassify'),
      formatter(row, column) {
        return getFormatter(that.materialClassifyFirstList, row[column])
      },
      isShow: true
    },
    // 物料二级分类
    {
      prop: 'materialSecondClassify',
      label: that.$t('lang.gles.common.materialSecondClassify'),
      formatter(row, column) {
        return getFormatter(that.materialClassifySecondList || [], row[column])
      },
      isShow: true
    },
    // 物料编码
    {
      prop: 'materialCode',
      label: that.$t('lang.gles.material.materialCode'),
      formatter(row, column) {
        return getFormatter(that.materialCodelist || [], row[column])
      },
      isShow: true
    },
    // 任务类型
    {
      prop: 'taskType',
      label: that.$t('lang.gles.strategy.taskType'),
      minWidth: 84,
      formatter(row, column) {
        return getFormatter(that.taskTypeList, row[column])
      },
      isShow: true
    },
    // 机器人系列
    {
      prop: 'robotType',
      label: that.$t('lang.gles.strategy.robotSeries'),
      formatter(row, column) {
        return getFormatter(that.robotTypeList, row[column])
      },
      isShow: true
    },
    // 机器人型号
    {
      prop: 'robotModel',
      label: that.$t('lang.gles.strategy.robotModel'),
      isShow: true,
      minWidth: 160,
      showOverflowTooltip: false,
      slotName: 'robotModelSlot'
    },
    // 容器面
    {
      prop: 'containerDirection',
      label: that.$t('lang.gles.base.containerDirection'),
      isShow: true,
      // minWidth: 132,
      showOverflowTooltip: false,
      slotName: 'containerDirectionSlot'
    },
    // 货位序号
    {
      prop: 'goodsPositionSerialNumber',
      label: that.$t('lang.gles.base.goodsPositionNum'),
      minWidth: 84,
      isShow: true,
      slotName: 'goodsPositionNumSlot'
    },
    // 状态
    {
      prop: 'status',
      label: that.$t('lang.gles.common.statusFlag'),
      slotName: 'enableStatus',
      width: 84,
      isShow: true
    },
    // 备注
    {
      prop: 'remark',
      label: that.$t('lang.gles.strategy.remark'),
      isShow: true
    }
  ]
  searchTableItem.forEach((item) => {
    item.showOverflowTooltip = item.showOverflowTooltip ?? true
  })
  return searchTableItem
}

// 编辑弹框 基础信息
export const getEditBaseFormData = (that, row) => {
  const editBaseInfoFormData = [
    // 策略编码
    {
      name: 'strategyCode',
      value: row.strategyCode || '',
      span: 8,
      component: 'elInput',
      label: that.$t('lang.gles.strategy.strategyCode'),
      rules: mapVerify(['required', 'inputCode']),
      clearable: true,
      appendSlotName: 'inputCodeTipIcon',
      class: 'tip-icon'
    },
    // 策略名称
    {
      name: 'strategyName',
      value: row.strategyName || '',
      span: 8,
      component: 'elInput',
      label: that.$t('lang.gles.strategy.strategyName'),
      placeholder: that.$t('lang.gles.common.inputLen200'),
      rules: mapVerify(['required', 'inputLen200']),
      clearable: true
    },
    // 备注
    {
      name: 'remark',
      value: row.remark || '',
      span: 6,
      component: 'elInput',
      label: that.$t('lang.gles.strategy.remark'),
      clearable: true
    }

  ]
  return editBaseInfoFormData
}
// 匹配条件
export const getMatchConditionFormData = (that, options, row) => {
  const matchConditionFormData = [
    // 周转容器类型
    {
      name: 'turnoverContainerType',
      value: row.turnoverContainerType || 0,
      span: 6,
      component: 'elSelect',
      label: that.$t('lang.gles.material.turnoverContainerType'),
      rules: mapVerify(['required']),
      clearable: true,
      data: formatOptions(options.turnoverContainerTypeList || []),
      mchange(val, item, cb) {
        that.getTurnoverContainerCodeList(val)
        cb({ turnoverContainerCode: 0 })
      }
    },
    // 周转容器编码
    {
      name: 'turnoverContainerCode',
      value: row.turnoverContainerCode || 0,
      span: 6,
      component: 'elSelect',
      label: that.$t('lang.gles.orderManage.turnoverContainerId'),
      rules: mapVerify(['required']),
      clearable: true,
      data: formatOptions(that.turnoverContainerCodeList || [])
    },
    // 物料一级分类
    {
      name: 'materialFirstClassify',
      value: row.materialFirstClassify || 0,
      span: 6,
      component: 'elSelect',
      label: that.$t('lang.gles.common.materialFirstClassify'),
      rules: mapVerify(['required']),
      clearable: true,
      data: formatOptions(options.materialClassifyFirstList || []),
      mchange: that.onClassifyFirstChange
    },
    // 物料二级分类
    {
      name: 'materialSecondClassify',
      value: row.materialSecondClassify || 0,
      span: 6,
      component: 'elSelect',
      label: that.$t('lang.gles.common.materialSecondClassify'),
      rules: mapVerify(['required']),
      clearable: true,
      data: formatOptions(that.classifySecondList || []),
      mchange: that.onClassifySecondChange
    },
    // 物料编码
    {
      name: 'materialCode',
      value: row.materialCode || 0,
      span: 6,
      component: 'elSelect',
      label: that.$t('lang.gles.material.materialCode'),
      rules: mapVerify(['required']),
      clearable: true,
      data: formatOptions(that.materialCodeList || [])
    },
    // 任务类型
    {
      name: 'taskType',
      value: row.taskType || 0,
      span: 6,
      component: 'elSelect',
      label: that.$t('lang.gles.strategy.taskType'),
      rules: mapVerify(['required']),
      clearable: true,
      data: formatOptions(options.taskTypeList.filter(item => Number(item.value) !== 50) || [])
    }
  ]
  return matchConditionFormData
}

// 货位配置
export const getGoodsPositionConfigFormData = (that, options, row = {}) => {
  const configFormData = [
    // 机器人系列
    {
      name: 'robotType',
      value: row.robotType || '',
      span: 6,
      component: 'elSelect',
      label: that.$t('lang.gles.strategy.robotSeries'),
      rules: mapVerify(['required']),
      clearable: true,
      data: options.robotTypeList.filter(item => Number(item.value) !== 3) || []
    },
    // 机器人型号
    {
      name: 'robotModel',
      value: row.robotModel || [],
      span: 6,
      component: 'elSelect',
      label: that.$t('lang.gles.strategy.robotModel'),
      rules: mapVerify(['required']),
      clearable: true,
      multiple: true,
      data: options.robotModelList || []
    },
    // 容器面
    {
      name: 'containerDirection',
      value: row.containerDirection || [],
      span: 6,
      component: 'elSelect',
      label: that.$t('lang.gles.base.containerDirection'),
      rules: mapVerify(['required']),
      clearable: true,
      multiple: true,
      data: options.containerDirectionList || []
    },
    // 货位序号
    {
      name: 'goodsPositionSerialNumber',
      value: row.goodsPositionSerialNumber || '',
      span: 6,
      component: 'elSelect',
      label: that.$t('lang.gles.base.goodsPositionNum'),
      rules: mapVerify(['required']),
      clearable: true,
      data: goodsPositionNum || []
    }
  ]
  return configFormData
}

