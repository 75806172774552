var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"basic-data-list"},[_c('query-view',{ref:"myQueryView",attrs:{"name":"strategyCode","value":_vm.baseFormModel.strategyCode,"placeholder":_vm.$t('lang.gles.strategy.pleaseInputStrategyCode'),"query-list":_vm.moreQueryData},on:{"update:value":function($event){return _vm.$set(_vm.baseFormModel, "strategyCode", $event)},"baseSearch":_vm.handleBaseSearch,"moreSearch":_vm.handleMoreSearch}}),_c('el-card',{staticClass:"table-card"},[_c('div',{staticClass:"operator-wrapper"},[_c('el-button',{attrs:{"type":"primary","icon":"el-icon-plus"},on:{"click":_vm.updateCom}},[_vm._v(" "+_vm._s(_vm.$t("lang.gles.common.addNew"))+" ")]),_c('el-button',{attrs:{"type":"primary","icon":"el-icon-circle-check","disabled":!_vm.selectValue.length},on:{"click":function($event){return _vm.batchChangeStatus(0)}}},[_vm._v(" "+_vm._s(_vm.$t("lang.gles.common.enable"))+" ")]),_c('el-button',{attrs:{"type":"danger","icon":"el-icon-circle-close","disabled":!_vm.selectValue.length},on:{"click":function($event){return _vm.batchChangeStatus(1)}}},[_vm._v(" "+_vm._s(_vm.$t("lang.gles.common.disable"))+" ")])],1),_c('m-table',{ref:"mTable",attrs:{"table-item":_vm.tableItem,"table-data":_vm.tableData,"page-data":_vm.pageData,"extend-config":_vm.tableExtendConfig},on:{"editItem":_vm.editItem,"delItem":_vm.delItem,"copyItem":_vm.copyItem,"pageChange":_vm.pageChange,"selection-change":function (val) { return (_vm.selectValue = val); }},scopedSlots:_vm._u([{key:"enableStatus",fn:function(ref){
var row = ref.row;
return [_c('el-switch',{staticClass:"switch",attrs:{"value":row.status,"active-text":_vm.$t('lang.gles.common.enable'),"inactive-text":_vm.$t('lang.gles.common.disable'),"active-value":0,"inactive-value":1},nativeOn:{"click":function($event){return _vm.changeStatus(row)}}})]}},{key:"robotModelSlot",fn:function(ref){
var row = ref.row;
return [(row.robotModel)?_c('span',_vm._l((row.robotModel.split(',')),function(item,index){return _c('el-tag',{key:index,attrs:{"effect":"plain","type":"info"}},[_vm._v(" "+_vm._s(item)+" ")])}),1):_vm._e()]}},{key:"containerDirectionSlot",fn:function(ref){
var row = ref.row;
return [(row.containerDirection)?_c('span',_vm._l((row.containerDirection.split(',')),function(item,index){return _c('el-tag',{key:index,attrs:{"effect":"plain","type":"info"}},[_vm._v(" "+_vm._s(item)+" ")])}),1):_vm._e()]}},{key:"goodsPositionNumSlot",fn:function(ref){
var row = ref.row;
return [(row.goodsPositionSerialNumber)?_c('el-tag',{attrs:{"effect":"plain","type":"info"}},[_vm._v(" "+_vm._s(row.goodsPositionSerialNumber)+" ")]):_vm._e()]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }